<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table>
        <h1>{{ name }}</h1>
      </template>
      <template v-slot:item-slot>
        <div class="breadcrumb-item">
          <router-link
            :to="{
              name: 'ProductShow',
              params: { id: product_stock[0].product_id },
            }"
            >{{ product_stock[0].name_product }}
          </router-link>
        </div>
      </template>
    </base-header>
    <section class="body">
      <div class="activities">
        <div class="activity" v-for="stock in product_stock" :key="stock">
          <div
            :class="{
              'bg-primary': stock.status === 'IN',
              'bg-danger': stock.status === 'OUT',
            }"
            class="activity-icon text-white shadow-primary"
          >
            <i class="fas fa-wine-bottle"></i>
          </div>
          <div class="activity-detail">
            <div class="mb-2">
              <span class="text-job text-primary">{{ stock.created_at }}</span>
              <span class="bullet"></span>
              <a class="text-job" href="#">{{ stock.status }}</a>
              <div class="float-right dropdown">
                <a href="#" data-toggle="dropdown"
                  ><i class="fas fa-ellipsis-h"></i
                ></a>
                <div class="dropdown-menu">
                  <div class="dropdown-title">Options</div>
                  <div class="dropdown-divider"></div>
                  <a href="#" class="dropdown-item has-icon text-danger delete"
                    ><i class="fas fa-trash-alt"></i> Archive</a
                  >
                </div>
              </div>
            </div>
            <p>{{ stock.stock }}/{{ stock.name_unit }}</p>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>
<script>
import { apiGet } from "../../../services/api";
import axios from "axios";
import swal from "sweetalert";
export default {
  data() {
    return {
      name: "Stock Detail",
      venues: [],
      product_stock: [],
      errors: {},
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let venue_id = this.$route.params.venue_id;
      let product_id = this.$route.params.product_id;

      apiGet("product_stock/" + venue_id + "/" + product_id)
        .then((result) => {
          this.product_stock = result.data.data;
          console.log(result.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteStock(id) {
      swal({
        title: "Are you sure?",
        text:
          "Once deleted, you will not be able to recover this imaginary file!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios
            .delete(this.$store.state.api + "product_stock/" + id, {
              headers: { Authorization: this.$store.state.token },
            })
            .then(
              () =>
                (this.product_stock = this.product_stock.filter((v) => {
                  return v.id != id;
                }))
            )
            .catch((err) => {
              console.log(err);
              swal("Error!", err.message, "error");
            });
          swal("Poof! Your product price file has been deleted!", {
            icon: "success",
          });
        } else {
          swal("Your product price file is safe!");
        }
      });
    },
  },
};
</script>
