var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section"},[_c('base-header',{scopedSlots:_vm._u([{key:"header-table",fn:function(){return [_c('h1',[_vm._v(_vm._s(_vm.name))])]},proxy:true},{key:"item-slot",fn:function(){return [_c('div',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{
            name: 'ProductShow',
            params: { id: _vm.product_stock[0].product_id },
          }}},[_vm._v(_vm._s(_vm.product_stock[0].name_product)+" ")])],1)]},proxy:true}])}),_c('section',{staticClass:"body"},[_c('div',{staticClass:"activities"},_vm._l((_vm.product_stock),function(stock){return _c('div',{key:stock,staticClass:"activity"},[_c('div',{staticClass:"activity-icon text-white shadow-primary",class:{
            'bg-primary': stock.status === 'IN',
            'bg-danger': stock.status === 'OUT',
          }},[_c('i',{staticClass:"fas fa-wine-bottle"})]),_c('div',{staticClass:"activity-detail"},[_c('div',{staticClass:"mb-2"},[_c('span',{staticClass:"text-job text-primary"},[_vm._v(_vm._s(stock.created_at))]),_c('span',{staticClass:"bullet"}),_c('a',{staticClass:"text-job",attrs:{"href":"#"}},[_vm._v(_vm._s(stock.status))]),_vm._m(0,true)]),_c('p',[_vm._v(_vm._s(stock.stock)+"/"+_vm._s(stock.name_unit))])])])}),0)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float-right dropdown"},[_c('a',{attrs:{"href":"#","data-toggle":"dropdown"}},[_c('i',{staticClass:"fas fa-ellipsis-h"})]),_c('div',{staticClass:"dropdown-menu"},[_c('div',{staticClass:"dropdown-title"},[_vm._v("Options")]),_c('div',{staticClass:"dropdown-divider"}),_c('a',{staticClass:"dropdown-item has-icon text-danger delete",attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-trash-alt"}),_vm._v(" Archive")])])])
}]

export { render, staticRenderFns }